<template>
  <div>
    <a-modal
      width="800px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="基础套餐详情"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <a-spin :spinning="spinning">
        <a-row>
          <a-col :span="12">
            <a-descriptions
              title="基本信息"
              :column="1"
              class="simiot-descriptions simiot-descriptions-max-6"
            >
              <a-descriptions-item label="基础套餐名称">
                {{ data.name }}
              </a-descriptions-item>

              <a-descriptions-item label="套餐类型">
                {{ data.product_type }}
              </a-descriptions-item>

              <a-descriptions-item label="运营商种类">
                {{ data.carrier_type }}
              </a-descriptions-item>

              <a-descriptions-item label="流量属性">
                {{ data.traffic_property }}
              </a-descriptions-item>

              <a-descriptions-item label="用户业务模式">
                {{ data.user_business_type }}
              </a-descriptions-item>

              <a-descriptions-item label="基础套餐描述">
                {{ data.remark }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              title="周期与容量"
              :column="1"
              class="simiot-descriptions simiot-descriptions-max-6"
            >
              <a-descriptions-item label="套餐周期">
                {{ data.service_cycle }}
              </a-descriptions-item>

              <a-descriptions-item label="套餐容量">
                {{ data.package_capacity }}
              </a-descriptions-item>

              <a-descriptions-item label="语音">
                {{ data.voice_capacity }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              title="零售设置"
              :column="1"
              class="simiot-descriptions simiot-descriptions-max-6"
            >
              <a-descriptions-item label="允许零售">
                {{ data.is_allow_retail }}
              </a-descriptions-item>

              <a-descriptions-item label="零售价设置者" v-if="data.is_allow_retail_slug">
                {{ data.retail_operator }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              title="其它设置"
              :column="1"
              class="simiot-descriptions simiot-descriptions-max-6"
            >
              <a-descriptions-item label="允许自订">
                {{ data.is_allow_self_subscribe }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="12">
            <a-descriptions title="价格设置" :column="priceSetColumn">
              <a-descriptions-item>
                周期数
              </a-descriptions-item>

              <a-descriptions-item>
                标准价(元)
              </a-descriptions-item>

              <a-descriptions-item>
                底价(元)
              </a-descriptions-item>

              <a-descriptions-item v-if="data.is_allow_retail_slug && data.retail_operator_slug === 'platform'">
                零售价(元)
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="priceSetColumn"
              v-for="favorablePrice in data.base_product_favorable_prices"
              :key="`favorable_price_${favorablePrice.cycles}`"
            >
              <a-descriptions-item>
                {{ favorablePrice.cycles }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ favorablePrice.distributor_price | formatFloat }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ favorablePrice.floor_price | formatFloat }}
              </a-descriptions-item>

              <a-descriptions-item v-if="data.is_allow_retail_slug && data.retail_operator_slug === 'platform'">
                {{ favorablePrice.floor_price | formatFloat }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="3"
              v-if="data.product_type_slug == 'common' && userBusinessType != 'custom_day' && userBusinessType != 'merchant_sales'"
            >
              <a-descriptions-item>
                超额计费单位
              </a-descriptions-item>

              <a-descriptions-item>
                超额标准价(元)
              </a-descriptions-item>

              <a-descriptions-item>
                超额底价(元)
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.excess_rate_quantity }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.excess_distributor_price | formatFloat }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.excess_floor_price | formatFloat }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="2"
              v-if="data.product_type_slug == 'common' && (userBusinessType == 'custom_month' || userBusinessType == 'traffic_share')"
            >
              <a-descriptions-item>
                停机保号标准价(元)
              </a-descriptions-item>

              <a-descriptions-item>
                停机保号底价(元)
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.stop_distributor_price | formatFloat }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.stop_floor_price | formatFloat }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions title="卡片设置" :column="3" v-if="data.product_type_slug == 'common'">
              <a-descriptions-item>
                卡片材质
              </a-descriptions-item>

              <a-descriptions-item>
                标准价(元)
              </a-descriptions-item>

              <a-descriptions-item>
                底价(元)
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="3"
              v-for="baseProductsCardType in data.base_products_card_types"
              :key="`card_type_${baseProductsCardType.id}`"
            >
              <a-descriptions-item>
                {{ baseProductsCardType.card_type_name }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ baseProductsCardType.distributor_price | formatFloat }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ baseProductsCardType.floor_price | formatFloat }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findBaseProduct } from '@/api/base_product'

export default {
  name: 'ShowBaseProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      userBusinessType: '',
      priceSetColumn: 3,
      spinning: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.spinning = true
      findBaseProduct(this.id).then((res) => {
        this.data = res.data
        this.userBusinessType = this.data.user_business_type_slug
        this.priceSetColumn = this.data.is_allow_retail_slug && this.data.retail_operator_slug === 'platform' ? 4 : 3
        this.spinning = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
